import React, { useEffect, useState } from 'react';
import './post-stylesheet.css';
import Navbar from '../navbar/navbar';

function Post(props) {
	let [text, setText] = useState('');
	let [filepath, setFilepath] = useState('');
	let [link, setLink] = useState('');
	let [header, setHeader] = useState('');
	let [loader, setLoader] = useState(true);
	let [loaderMessage, setLoaderMessage] = useState('');
	let [textGerman, setTextGerman] = useState('');
	let [skills, setSkills] = useState([]);
	let [inputValue, setInputValue] = useState('');
	let [imageInput, setImageInput] = useState('');
	let [frontPage, setFrontPage] = useState('');
	let [showTextGer, setShowTextGer] = useState('');
	let [showTextEng, setShowTextEng] = useState('');
	let [projectImage, setProjectImages] = useState([]);
	// eslint-disable-next-line
	let newData = {
		text: text,
		filepath: filepath,
		link: link,
		header: header,
		id: props.data.id,
		textGerman: textGerman,
		skills: skills,
		showTextEng: showTextEng,
		showTextGer: showTextGer,
		frontPage: frontPage,
		projectImage: projectImage,
	};

	useEffect(() => {
		if (props.data) {
			console.log(props.data);
			setText(props.data.text || '');
			setFilepath(props.data.filepath || 0);
			setLink(props.data.link || 0);
			setHeader(props.data.postHeader || '');
			setTextGerman(props.data.textGerman || '');
			setFrontPage(props.data.frontPage || '');
			setShowTextEng(props.data.showTextEng || '');
			setShowTextGer(props.data.showTextGer || '');
			setSkills(props.data.skills || []);
			setProjectImages(props.data.projectImage || []);
		}
		// eslint-disable-next-line
	}, [props.data]);

	function callLoader() {
		setLoader(false);
		setTimeout(() => {
			setLoader(true);
			setLoaderMessage('success');
			setTimeout(() => {
				setLoaderMessage('');
			}, 1000 * 5);
		}, 1000 * 3);
	}

	const addValue = () => {
		if (inputValue.trim() !== '') {
			setSkills([...skills, inputValue]);
			setInputValue('');
		}
	};
	const removeSkill = (indexToRemove) => {
		setSkills(skills.filter((_, index) => index !== indexToRemove));
	};
	const addImage = () => {
		if (imageInput.trim() !== '') {
			setProjectImages([...projectImage, imageInput]);
			setImageInput('');
		}
	};
	const removeImage = (indexToRemove) => {
		setProjectImages(
			projectImage.filter((_, index) => index !== indexToRemove)
		);
	};
	return (
		<>
			<section>
				<div className="Post-Container">
					<div className="Post-Form">
						<div className="Post-Header">
							<h2 className="Post-Header">
								{props.postHeader}
								<div class="col-3">
									<div hidden={loader} class="snippet" data-title="dot-spin">
										<div class="stage">
											<div class="dot-spin"></div>
										</div>
									</div>
								</div>
							</h2>
						</div>
						<div className="Post-Navbar">
							<Navbar backgroundColor={'#333'} clearData={props.clearData} />
						</div>
						<div className="Post-SubHeader">
							<input
								id="header"
								type="text"
								value={header}
								onChange={(e) => {
									setHeader(e.target.value);
								}}
							/>
						</div>
						<textarea
							id="text"
							className="Post-Text-1"
							value={text}
							onChange={(e) => {
								setText(e.target.value);
							}}
						/>
						<textarea
							id="text"
							className="Post-Text-2"
							value={textGerman}
							onChange={(e) => {
								setTextGerman(e.target.value);
							}}
						></textarea>
						<textarea
							value={showTextGer}
							onChange={(e) => {
								setShowTextGer(e.target.value);
							}}
							className="Show-Text-GER"
						></textarea>
						<textarea
							value={showTextEng}
							onChange={(e) => {
								setShowTextEng(e.target.value);
							}}
							className="Show-Text-ENG"
						></textarea>
						<div className="Post-Filepath">
							<input
								id="filepath"
								type="text"
								value={filepath}
								onChange={(e) => {
									setFilepath(e.target.value);
								}}
							/>
						</div>
						<div className="Post-Link">
							<input
								id="link"
								type="text"
								value={link}
								onChange={(e) => {
									setLink(e.target.value);
								}}
							/>
						</div>
						<div className="Post-Front">
							<input
								type="text"
								value={frontPage}
								placeholder="Frontpage Ja oder Nein"
								onChange={(e) => {
									setFrontPage(e.target.value);
								}}
							></input>
						</div>
						<div className="Post-Skills">
							<div className="skills">
								<div className="skills-container">
									{skills.map((skills, i) => {
										return (
											<p className="skills-p" key={i}>
												{skills}{' '}
												<button
													onClick={() => removeSkill(i)}
													className="skills-button"
												>
													x
												</button>
											</p>
										);
									})}
								</div>
							</div>
							<input
								type="text"
								placeholder="Add Skills"
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										addValue();
									}
								}}
							/>
						</div>
						<div className="Post-Images">
							<div className="images">
								<div className="images-container">
									{projectImage.map((images, i) => {
										return (
											<p className="images-p" key={i}>
												{images}{' '}
												<button
													onClick={() => removeImage(i)}
													className="skills-button"
												>
													x
												</button>
											</p>
										);
									})}
								</div>
								<input
									type="text"
									placeholder="Add Image"
									value={imageInput}
									onChange={(e) => setImageInput(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											addImage();
										}
									}}
								/>
							</div>
						</div>{' '}
						<button
							onClick={() => {
								props.buttonOnClick(newData);
								callLoader();
							}}
							className="Post-Button"
						>
							{loaderMessage ? loaderMessage : props.button}
						</button>
					</div>
				</div>
			</section>
		</>
	);
}

export default Post;
